import { toggleMenu } from "./header";
import { scrollToHash, getSamePageAnchor } from "./smooth-scrolling";
import '../css/main.css';


document.addEventListener("DOMContentLoaded", () => {
  /**
   * Toggle del header
   */
  toggleMenu()

  /**
   * Example carousel import
   */
  const carousel = document.querySelectorAll(".carousel");
  if (carousel.length > 0) {
    import("./carousel").then((module) => {
      carousel.forEach((slider) => {
        module.carousel(slider);
      });
    });
  }

  /**
   * Slider carousel import
   */
  const slider = document.querySelectorAll(".slider");
  if (slider.length > 0) {
    import("./slider").then((module) => {
      slider.forEach((slider) => {
        module.slider(slider);
      });
    });
  }

  /**
   * Smooth scrolling support
   */
  // If a link's href is within the current page, scroll to it instead
  document.querySelectorAll('a[href]').forEach(a => {
    a.addEventListener('click', e => {
      scrollToHash(getSamePageAnchor(a), e);
    });
  });
  // Scroll to the element in the URL's hash on load
  scrollToHash(window.location.hash);

  /**
   * ACF7 event listener
   */
  document.addEventListener('wpcf7mailsent', (event) => {
    gtag('event', 'Formulario', {
      'event_category': event.detail.contactFormId,
      'event_label': event.detail.unitTag
    });
  }, false);

  /**
   * Handle archive breadcrumb
   */
  const archiveBreadcrumb = document.querySelectorAll("#archiveBreadcrumb");
  if (archiveBreadcrumb.length > 0) {
    import("./breadcrumb").then((module) => {
      archiveBreadcrumb.forEach((breadcrumb) => {
        module.handleBreadcumb(breadcrumb);
      });
    });
  }

  /**
   * Single product
   */
  const singleProductContainer = document.querySelector('.single-product-details');
  if (singleProductContainer) {
    import('./single-product-slider').then((module) => {
      module.carousel();
    });
  }

  /**
   * Whatsapp button
   */
  const whatsappButton = document.getElementById('whatsappButton');
  if (whatsappButton) {
    import('./whatsappButton').then((module) => {
      module.whatsappHandler();
    });
  }
});



